@font-face {
  font-family: 'kelson_sansbold';
  src: url('../fonts/kelson_sans_bold-webfont.woff2') format('woff2'),
    url('../fonts/kelson_sans_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kelson_sans';
  src: url('../fonts/kelson_sans_regular-webfont.woff2') format('woff2'),
    url('../fonts/kelson_sans_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
